<template>
<div>
  <b-container>
    <b-row class="mb-4">
      <b-col >
        <b-card class="mb-3">
          <b-form inline>
            <label for="startdate">Start date</label>
            <b-form-datepicker id="startdate" v-model="startDate" class="mb-2 mr-4 ml-2"></b-form-datepicker>
            <label for="enddate">End date</label>
            <b-form-datepicker id="enddate" v-model="endDate" class="mb-2 ml-2 mr-4"></b-form-datepicker>
          </b-form>
          <b-button variant="primary" v-on:click="reload">Apply</b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mb-4" v-if="!firstRun">
      <b-col>
        <b-pagination
          v-model="page"
          :limit=10
          :total-rows="totalRows"
          :per-page="pageSize"
        />
        Total news: {{totalRows}}
        <span v-if="!loadingNews">
          <b-card class="mb-3" v-for="item in news" :key="item.id">
            <publication
              :hideLink="false"
              :propItem="item"
              :key="item.id"
              :username="user.username"
              :tagsProp=tags
              :permissionAddArticle="permissionAddArticle"
              :permissionAddRisk="permissionAddRisk"
              :permissionAddTag="permissionAddTag"
              :permissionArticlesWip="permissionArticlesWip"
              :permissionDeletePublication="permissionDeletePublication"
              :permissionReadTagsPanel="permissionReadTagsPanel"
            />
          </b-card>
        </span>
        <span v-show="loadingNews">
          <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
        </span>
        <b-pagination
          v-model="page"
          :limit=10
          :total-rows="totalRows"
          :per-page="pageSize"
        />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '../libs/accesscontrol'
import moment from 'moment'
import Publication from '@/components/PublicationNew'

export default {
  props: ['publisher'],
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    let apiName = 'cosmos'
    this.tags = await this.$Amplify.API.get(apiName, '/tags')
    this.load()
  },
  data () {
    return {
      collapseVisible: false,
      endDate: moment().format('YYYY-MM-DD'),
      firstRun: true,
      loadingNews: false,
      news: [],
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionReadAnalytics: false,
      permissionReadTagsPanel: false,
      startDate: moment().subtract(365, 'days').format('YYYY-MM-DD'),
      tags: [],
      totalRows: 0,
      pageSize: 20,
      page: 1
    }
  },
  methods: {
    load: async function () {
      this.$logger.debug('loading started')
      this.loadingNews = true
      try {
        let apiName = 'cosmos'
        let path = `/news/filter/version/1`
        let params = {
          body: {
            endDate: moment(this.endDate).add(1, 'days'),
            page: this.page,
            pageSize: this.pageSize,
            publishers: [this.publisher],
            startDate: this.startDate
          }
        }
        let response = await this.$Amplify.API.post(apiName, path, params)
        this.$logger.debug(response)
        this.news = response.results
        this.totalRows = response.total
        this.$logger.debug(this.news)
        this.firstRun = false
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn('loading error' + e)
      }
    },
    reload: function () {
      this.page = 1
      this.totalRows = 0
      this.collapseVisible = false
      this.load()
    }
  },
  watch: {
    page: 'load'
  }
}
</script>

<style>
</style>
