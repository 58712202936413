<template>
  <b-container fluid>
    <b-row class="text-white bg-kpmg-light-green py-4 mb-4" v-if="!loading">
      <b-col>
        <h1 class="kstyle">{{$route.params.code}}</h1>
        <div>{{row.regulator.comments}}</div>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <div class="mb-2">
          <b-button class="mr-2" variant="outline-primary" :to="{ name: 'Regulators' }">back to regulators</b-button>
          <b-button class="mr-2" variant="outline-primary" :to="{ name: 'NewsAdvancedFilter', query: { publisher: this.$route.params.code } }">advanced filter</b-button>
          <span v-if="row.regulator.id > 0">
           <b-button class="mr-2" v-if="permission" variant="outline-primary" :to="{ name: 'RegulatorEdit', params: { id: row.regulator.id }}">Edit</b-button>
          </span>
        </div>
        <news-regulator :publisher="$route.params.code" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'

import NewsRegulator from '@/components/NewsRegulator.vue'

export default {
  name: 'Regulator',
  components: {
    NewsRegulator
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    document.title = "KPMG Regulatory Horizon: Regulator"
    this.$stat.log({ page: 'regulator', action: 'open regulator', payload: { regulator: this.$route.params.code } })
    this.permission = ac.can(this.user.acgroups).createAny('regulator').granted
    this.loadRows()
  },
  data () {
    return {
      news: [],
      limit: 50,
      loading: true,
      loadingNews: true,
      loadingTopics: true,
      permission: false,
      row: ''
    }
  },
  methods: {
    loadRows: async function () {
      this.$logger.debug('loadRisks started')
      this.loading = true
      try {
        let apiName = 'cosmos'
        let path = `/regulator/page/${this.$route.params.code}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.row = response
        this.loading = false
      } catch (e) {
        this.$logger.warn('/regulator/:code error ', e)
      }
    }
  }
}
</script>
